import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  useAuth,
  getLoggedInUserData,
  getToken,
} from "../../context/AuthContext";
import useApi from "../../utility/apiCall";
import Breadcrumb from "../../components/Breadcrumb";

export default function TechnicalAnalysis() {
  const [loading, setLoading] = useState(true);
  const { state } = useAuth();

  const { apiCall } = useApi(); // Use the apiCall function
  

  return (
    <>
        <Breadcrumb className="mb-0" breadcrumbIcon="PresentationChartBarSvg" breadcrumbHeading="Technical Analysis" />
        <div className="technical-analysis-bx">
          <iframe
              src="https://webapp.autochartist.com/componentcontainer/broker.php?broker_id=950&user=email&logintoken=874f64dbc91075ed8fe10444f152f92f&expire=1780178400&account_type=LIVE&locale=en_GB"
              title="Technical Analysis"
          ></iframe>
        </div>
    </>
  );
}
