import React, { useState, useEffect, useRef } from 'react';
import { useAuth, getLoggedInUserData, getToken } from '../../context/AuthContext';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
//import FlashMessage from '../../components/FlashMessage'; 

// Icons Files
import Icons from '../../components/icons';

// Design Files
// import CommonLayout from '../PageCommonLayout/CommonLayout'
import Breadcrumb from '../../components/Breadcrumb';
import TransactionList from '../TransactionList';
//import TransactionItem from '../TransactionItem';
import FilterDropItem from '../../components/Filters/FilterDropItem';
import FilterRadioInput from '../../components/Filters/FilterRadioInput';

export default function PageTransactions() {
  const { apiCall } = useApi();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const [transactions, setTransactions] = useState(null);
  const isInitialMount = useRef(true);
  const [transferTypeFilter,setTransferTypeFilter] = useState(null);
  const [statusFilter,setStatusFilter] = useState(null);
  const [displayTransferTypeFilter,setDisplayTransferTypeFilter] = useState('All Transactions');
  const [displayStatusFilter,setDisplayStatusFilter] = useState('All Status');
  const [perPage,setPerPage] = useState(10);
  const [page,setPage] = useState(1);
  const [totalPages,setTotalPages] = useState(0);
  const [isLoading, setIsLoading ] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;
      
      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            // const transactions = await getUserTransactions();
            // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
          } catch (error) {
            console.error('Failed to fetch wallet group data:', error);
          }
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
    }
  }, [token]);

  const getUserTransactions = async () => {
    // Logic to get user wallet group-wise data
    try {
      setIsLoading(true);
      const response = await apiCall(API_URL +'get-transactions', {
          user_id: state.userData.id,
          token: token,
          page:page,
          per_page:perPage,
          transfer_type:transferTypeFilter,
          transaction_status:statusFilter
      });
      if (response.data.success == '0') {
        // console.log('response.data.data.data',response.data.data.data);
        
        setTransactions(response.data.data.data);
        setTotalPages(response.data.data.last_page)
      }
      setIsLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };
  const getUserTransactionsExport = async () => {
    try {
        setIsLoading(true);
        const response = await apiCall(API_URL + 'get-transactions-export', {
            user_id: state.userData.id,
            token: token,
            transfer_type: transferTypeFilter,
            transaction_status: statusFilter,
            nopaginate: 1 // Ensure all data is included
        }, { responseType: 'blob' }); // Specify response type as blob for file download

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'transactions_export.csv'); // Set the file name
        document.body.appendChild(link);
        link.click();

        setIsLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if(transferTypeFilter !== ''){
  //     if(transferTypeFilter !== null){
  //       setDisplayTransferTypeFilter(toTitleCase(transferTypeFilter));
  //     }
  //     getUserTransactions();
  //   }
  // }, [transferTypeFilter]);

  // useEffect(() => {
  //   if(statusFilter !== ''){
  //     if(statusFilter !== null){
  //       setDisplayStatusFilter(toTitleCase(statusFilter));
  //     }
  //     getUserTransactions();
  //   }
  // }, [statusFilter]);

  // useEffect(() => {
  //   if(parseInt(page) > 0){
  //     getUserTransactions();
  //   }
  // }, [page]);

  useEffect(() => {
    if(statusFilter !== null){
      setDisplayStatusFilter(toTitleCase(statusFilter));
      // getUserTransactions();
    }
    if(transferTypeFilter !== null){
      setDisplayTransferTypeFilter(toTitleCase(transferTypeFilter));
      // getUserTransactions();
    }

    if (parseInt(page) > 0 && transferTypeFilter !== null && statusFilter !== null) {
      console.log('1');
      
      getUserTransactions();
    }

    if (parseInt(page) > 0 && transferTypeFilter == null && statusFilter !== null) {
      console.log('2');
      getUserTransactions();
    }

    if(parseInt(page)  > 0 && transferTypeFilter !== null && statusFilter == null){
      console.log('3');
      getUserTransactions();
    }

    if(parseInt(page)  > 0 && transferTypeFilter == null && statusFilter == null){
      console.log('4');
      if (transferTypeFilter == null && statusFilter == null) {
        getUserTransactions();
      }
    }

  }, [perPage,statusFilter,transferTypeFilter]);

  const handleTransactionTypeChange = (transfer_type) => {
    if(transfer_type != 'All Transactions'){
      setTransferTypeFilter(transfer_type.replace(" ","_").toLowerCase());
    }
    else {
      setTransferTypeFilter(null)
      setDisplayTransferTypeFilter(transfer_type);
    }
  };

  const handleStatusChange = (status) => {
    if(status != 'All Status'){
      setStatusFilter(status.replace(" ","_").toLowerCase());
    } else {
      setStatusFilter(null);
      setDisplayStatusFilter(status);
      
    }
  };

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  const handlePerPageChange = (perPage) => {
    setPerPage(parseInt(perPage));
  }

  const changePrevPage = () => {
    if(page > 1){
      setPage(page-1);
    }

  }
  const changeNextPage = () => {
    if(page < totalPages){
      setPage(page+1);
    }
  }
  
  // Mobile Filter Js Start
  const [isMobileFilter, setIsMobileFilter] = useState(false);

  const handleMobileFilterOpen = () => {
    setIsMobileFilter(true);
  };

  const handleMobileFilterClose = () => {
    setIsMobileFilter(false);
  };

  const handleClearOption = () => {
    handleMobileFilterClose();
    setStatusFilter(null);
    setTransferTypeFilter(null);
    setPerPage(10);
  }

  const toTitleCase = (str) => {
    if(str === '' || str === null)
     return '';
    return str
      .replace(/_/g, ' ') // Replace underscores with spaces
      .split(' ') // Split the string into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(' '); // Join the words back into a single string
  };
  // Mobile Filter Js End

  // Desktop Filter otions
  const TransactionTypeOptions = ['All Transactions','Deposit', 'Withdraw', 'Transfer'];
  const StatusOptions = ['All Status','Pending','In Progress', 'Done', 'Rejected','Cancelled','Partial'];
  const RowsOptions = ['10','20', '50', '100'];
  
  // Desktop Filter otions
  
  return (
    <>
    
        <Breadcrumb breadcrumbIcon="TransactionsSvg" breadcrumbHeading="Transactions" />
 

        {/* --- Filter Section Start --- */}
        <div className="d-none d-md-block common-box cb-sticky cbs-filter-top">
          <div className="filter-bx">

            <div className="filter-left"></div>
            <div className="filter-left d-flex column-gap-2 align-items-center">
              <FilterDropItem label={displayTransferTypeFilter} groupName="transactionType" options={TransactionTypeOptions} onChange={handleTransactionTypeChange} selected={displayTransferTypeFilter}/>
              <FilterDropItem label={displayStatusFilter} groupName="status"  options={StatusOptions} onChange={handleStatusChange} selected={displayStatusFilter}/>
            </div>

          </div>
        </div>
        {/* --- Filter Section End --- */}

        <div className="container-lg pt-3 pb-5 px-lg-4">

          <div className="row row-gap-4">

            <div className="col-12">
              {transactions && transactions.length > 0 && (<div className="common-btn-item cbi-fill cbi-small mb-3 ms-auto" onClick={getUserTransactionsExport}><span><Icons.ArrowexportSvg />Export</span></div>)}
              <div className="common-box">
                  <TransactionList transactions={transactions} loading={isLoading}/>


                  {/* --- Filter Section Start --- */}

                  <div className="filter-bx transactions-bottom-filter mt-4">

                    <div className="filter-left">

                        {transactions && transactions.length > 0 &&
                        <div className="d-none d-md-flex  column-gap-2 align-items-center justify-content-center">
                          <div className="sgr-outer-label mb-0">Rows per page</div>
                          <FilterDropItem className="fdih-36 fdb-view-bl" label={perPage} groupName="view" options={RowsOptions} onChange={handlePerPageChange}/>
                        </div>
                        }

                        <div className="d-flex d-md-none common-btn-item cbi-fill" onClick={handleMobileFilterOpen}>
                          <span><Icons.FilterSvg />Filter</span>
                        </div>
                    </div>

                    {transactions && transactions.length > 0 &&
                      <div className="filter-right">
                        <div className="pagination-bx">
                            <div className="pagination-btn pb-left" onClick={changePrevPage}>Prev</div>
                            <div className="pagination-text"><div>{page}</div> <span>/</span> <div>{totalPages}</div></div>
                            <div className="pagination-btn pb-right" onClick={changeNextPage}>Next</div>
                        </div>
                      </div>
                    }

                  </div>
                  {/* --- Filter Section End --- */}
              </div>
            </div>
            
          </div>

        </div>


        {/* --- Mobile Filter Section Start --- */}
        <div className={`mobile-filter-bx ${isMobileFilter ? "active" : ""}`}>
          <div className="mfb-header">
              <div className='mfb-header-heading'><Icons.FilterSvg />Filter</div>
              <span className="close-icon" onClick={handleMobileFilterClose}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                </svg>
              </span>
          </div>
          <div className="mfb-body">
            

            <div className="sgr-outer-label">Transaction</div>
            <div className="filter-checkbox-group fcg-flex-row check-icon-hide check-square">
              <FilterRadioInput id="transactioncb1" name="TransactionCb" label={"All Transactions"} onChange={handleTransactionTypeChange} checked={displayTransferTypeFilter === 'All Transactions'}/>
              <FilterRadioInput id="transactioncb2" name="TransactionCb" label="Deposit" onChange={handleTransactionTypeChange} checked={displayTransferTypeFilter === 'Deposit'}/>
              <FilterRadioInput id="transactioncb3" name="TransactionCb" label="Withdraw" onChange={handleTransactionTypeChange} checked={displayTransferTypeFilter === 'Withdraw'}/>
              <FilterRadioInput id="transactioncb4" name="TransactionCb" label="Transfer" onChange={handleTransactionTypeChange} checked={displayTransferTypeFilter === 'Transfer'}/>
              {/* <FilterRadioInput id="transactioncb5" name="TransactionCb" label="Internal transfer" onChange={handleTransactionTypeChange}/> */}
            </div>

            <div className="filter-sapretor"></div>

            <div className="sgr-outer-label">Status</div>
            <div className="filter-checkbox-group fcg-flex-row check-icon-hide check-square">
              <FilterRadioInput onChange={handleStatusChange} id="statuscb1" name="statusCb" label="All Status" checked={displayStatusFilter === 'All Status'}/>
              <FilterRadioInput onChange={handleStatusChange} id="statuscb2" name="statusCb" label="Pending" checked={displayStatusFilter === 'Pending'}/>
              <FilterRadioInput onChange={handleStatusChange} id="statuscb3" name="statusCb" label="In Progress" checked={displayStatusFilter === 'In Progress'}/>
              <FilterRadioInput onChange={handleStatusChange} id="statuscb4" name="statusCb" label="Done" checked={displayStatusFilter === 'Done'}/>
              <FilterRadioInput onChange={handleStatusChange} id="statuscb5" name="statusCb" label="Rejected" checked={displayStatusFilter === 'Rejected'}/>
              <FilterRadioInput onChange={handleStatusChange} id="statuscb6" name="statusCb" label="Cancelled" checked={displayStatusFilter === 'Cancelled'}/>
              <FilterRadioInput onChange={handleStatusChange} id="statuscb7" name="statusCb" label="Partial" checked={displayStatusFilter === 'Partial'}/>
            </div>

            <div className="filter-sapretor"></div>

            <div className="sgr-outer-label">Rows per page</div>
            <div className="filter-checkbox-group fcg-flex-row check-icon-hide check-square">
              <FilterRadioInput id="rowscb1" name="rowsCb" label="10" onChange={handlePerPageChange} checked={perPage === 10}/>
              <FilterRadioInput id="rowscb2" name="rowsCb" label="20" onChange={handlePerPageChange} checked={perPage === 20}/>
              <FilterRadioInput id="rowscb3" name="rowsCb" label="50" onChange={handlePerPageChange} checked={perPage === 50}/>
              <FilterRadioInput id="rowscb4" name="rowsCb" label="100" onChange={handlePerPageChange} checked={perPage === 100}/>
            </div>

          </div>
          <div className="mfb-footer">
              <div className="d-flex flex-wrap justify-content-center gap-2">
                <div className="common-btn-item cbi-outline" onClick={handleClearOption}><span>Clear</span></div>
                <div className="common-btn-item cbi-fill" onClick={handleMobileFilterClose}><span>Apply</span></div>
              </div>
          </div>
        </div>

        <div className={`filter-ovarlay  ${isMobileFilter ? "active" : ""}`} onClick={handleMobileFilterClose}></div>
        {/* --- Mobile Filter Section End --- */}

    </>
  )
}
